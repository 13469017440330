<template>
  <v-dialog
    v-model="activo"
    max-width="60%"
  >
    <v-card flat>
      <v-card-title class="pa-0">
        <v-toolbar :color="color">
          <v-toolbar-title class="white--text">
            <v-icon class="ma-2" color="white" v-if="icono.toString().length > 0">{{icono}}</v-icon> {{ titulo }}
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12">
            <p style="font-size: 20px" class="text-center"><strong>{{texto}}</strong></p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center pb-4">
        <v-btn color="success" @click="emitirAccion()">Aceptar</v-btn>
        <v-btn color="warning" @click="activo = false">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    }
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalDecision', value)
      }
    },
    titulo: {
      get(){
        return this.datos.titulo
      }
    },
    texto: {
      get(){
        return this.datos.texto
      }
    },
    icono: {
      get(){
        return this.datos.icono
      }
    },
    color: {
      get(){
        return this.datos.color
      }
    }
  },
  methods: {
    emitirAccion(){
      this.$emit('ejecutar', true)
      this.activo = false
    }
  },
}
</script>

<style>

</style>