<template>
  <v-dialog
    v-model="modalActivo"
    max-width="1000px"
  >
    <v-card  class="mx-auto" max-width="1000px">
      <v-card-title class="green white--text" height="40%">
        <span class="headline">Datos Cupón</span>
      </v-card-title>
      <v-card-text>
        <v-row class="pt-4">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Titular
            <v-text-field
              v-model="datosCupon.titular"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Tarjeta
            <v-text-field
              v-model="datosCupon.tarjeta_marca"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Tarjeta Numero
            <v-text-field
              v-model="datosCupon.tarjeta_numero"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Financiación
            <v-text-field
              v-model="datosCupon.financiacion"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="4" class="py-1">
            Fecha Cupón
            <v-text-field
              v-model="datosCupon.fecha_cupon"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            Importe cobrado
            <v-text-field
              v-model="datosCupon.importe_cobrado"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            Codigo Autorizacion
            <v-text-field
              v-model="datosCupon.codigo_autorizacion"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="4" class="py-1">
            Lote
            <v-text-field
              v-model="datosCupon.lote"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            Cupón
            <v-text-field
              v-model="datosCupon.numero"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            Correo
            <v-text-field
              v-model="datosCupon.correo"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="warning"  @click="modalActivo = false">Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    activo: {
      type: Boolean,
      default: false
    },
    datosCupon: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      modalActivo: false
    }
  },
  methods: {
    closeDialogDatosCupon(){
      this.$emit('cerrarModalVLP')
    }
  },
  watch: {
    activo: function(){
      this.modalActivo = this.activo
    },
    modalActivo: function(){
      if (this.modalActivo == false){
        this.$emit('cerrarModalVLP')
      }
    }
  },
}
</script>

<style>

</style>