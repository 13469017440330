<template>
  <div>
    <v-dialog
      v-model="modalActivo"
      max-width="1000px"
    >
      <v-card max-width="1000px">
        <v-card-title class="pa-0">
          <v-toolbar color="primary" class="white--text" flat>
            <v-icon dark class="ma-2">fas fa-link</v-icon>Generar Link de Pago
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <br/>
        <v-card-text>
          <v-switch
            v-model="ingresaFinanciacion" 
            label="Ingresar Financiacion" 
            v-if="showLoading && permiteIngresarFinanciacion == 1"
          ></v-switch>
          <div class="text-center" v-if="showLoading">
            <transition name="slide-fade" >
              <v-chip class="ma-2" color="orange darken-4" outlined pill v-if="ingresaFinanciacion">
                <v-icon left>fas fa-exclamation-circle</v-icon>
                    Los intereses no se calculan.Ingrese el importe final con el recargo correspondiente.         
                <v-icon right>fas fa-exclamation-circle</v-icon>                         
              </v-chip>
            </transition>
          </div>
          <v-form ref="formDatosLink" v-model="validDatosLink" lazy-validation>
            <v-row v-if="showLoading">
              <v-col cols="12" sm="3" md="3" class="py-1">
                Importe
                <v-text-field
                  v-model="importe"
                  :rules="nameRules"
                  outlined
                  dense
                  required
                  type="number"
                ></v-text-field>
              </v-col>
              <transition name="slide-fade" v-if="ingresaFinanciacion">
                <v-col cols="12" sm="3" md="3" class="py-1" v-if="ingresaFinanciacion">
                  Tipo Tarjeta
                  <v-autocomplete
                    v-model="tipo_tarjeta"
                    :items="optionsTarjetas"
                    item-text="TARJETA"
                    return-object
                    :rules="financiacionRules"
                    placeholder="Seleccione tarjeta"
                    @change="filterFinanciacion()"                                                         
                    outlined dense required clearable    
                  ></v-autocomplete> 
                </v-col>
              </transition>
              <transition name="slide-fade" v-if="ingresaFinanciacion">
                <v-col cols="12" sm="6" md="6" class="py-1" v-if="ingresaFinanciacion">
                  Forma de Pago
                  <v-autocomplete
                    v-model="forma_pago"
                    :items="optionsFormaPagoFilter"
                    item-text="FINANCIACION"
                    return-object
                    :rules="financiacionRules"
                    placeholder="Seleccione forma de pago"                                                        
                    outlined dense required clearable                                                                                                         
                  ></v-autocomplete> 
                </v-col>
              </transition>
              <v-col cols="12" sm="4" md="4" class="py-1">
                Descripcion
                <v-text-field 
                  v-model="descripcion" 
                  :rules="nameRules" 
                  outlined 
                  dense 
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1">
                Telefono
                <v-text-field 
                  v-model="telefono" 
                  :rules="nameRules" 
                  outlined 
                  dense 
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" :sm="ingresaFinanciacion == true ? 6 : 3" :md="ingresaFinanciacion == true ? 6 : 3" class="py-1">
                Correo
                <v-text-field 
                  v-model="correo" 
                  outlined 
                  dense 
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12" v-if="showLoading">
              Link Pago
              <v-text-field 
                v-model="srvlinks" 
                outlined 
                dense 
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-container v-if="!showLoading">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-col class="subtitle-1 text-center pt-8" cols="12">
                  Generando Link de pago
              </v-col>
              <v-col cols="6">
                <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col cols="6" sm="6" md="6">                             
            <v-btn small block outlined color="warning" @click="modalActivo = false">Cancelar</v-btn>
          </v-col>
          <v-col cols="6" sm="6" md="6">                                        
            <v-btn small block color="success" @click="generarlink()">Generar Link</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Resultado Creacion Boton de Pago -->
    <v-dialog
      v-model="dialogLinkSuccess"
      width="600"
    >
      <v-card>
        <v-col cols="12" sm="12" md="12">
          <br/>
          <v-container width="700">
            <br/>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-img  class="mx-4" :src="pathLogoSuccess"  max-height="90" max-width="100" contain></v-img>
              <v-spacer></v-spacer>
            </v-toolbar>
            <br/>
            <div  class="text-center">
              <p class="titleSuccesMsj"> Link de Pago Generado Correctamente</p>                                     
            </div>
            <v-card-text class="text-center">
              <v-row>
                <v-text-field v-model="srvlinks" outlined readonly dense  id="link-pago-generado"></v-text-field>
              </v-row>           
              <v-row>
                <v-btn small outlined  dark color="warning" @click="dialogLinkSuccess = false">Cerrar</v-btn>
                <v-spacer></v-spacer>
                <v-btn small  dark color="primary" @click="copyLink()">Copiar Link</v-btn>
              </v-row>
            </v-card-text>
          </v-container>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    activo: {
      type: Boolean,
      default: false
    },
    empresa: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      modalActivo: false,
      ingresaFinanciacion: false,
      showLoading: true,
      permiteIngresarFinanciacion: '',
      validDatosLink: true,
      importe: '',
      nameRules: [
        v => !!v || 'Campo obligatorio.',
      ],
      financiacionRules: [
        v => !!v || 'Campo obligatorio.',
      ],
      tipo_tarjeta: {},
      optionsTarjetas: [],
      forma_pago: {},
      optionsFormaPagoFilter: [],
      descripcion: '',
      telefono: '',
      correo: '',
      srvlinks: '',
      dialogLinkSuccess: false,
      pathLogoSuccess: '/img/success_logo.png',
      currentLocation: '',
      optionsFormaPago: []
    }
  },
  created () {
    this.getFinanciaciones()
    this.getInfoEmpresa()
  },
  methods: {
    async getFinanciaciones(){
      this.currentLocation = window.location.href
      this.$store.state.modalCarga = true
      // pido financiaciones
      let peticion = await this.$store.dispatch('cobros/getFinanciaciones', {empresa: this.empresa})
      if (peticion.resultado == 0){
        this.$store.state.modalCarga = false
        // solo muestro el error
        this.$store.state.msjSnackBar = peticion.msj
        this.$store.state.colorSnackBar = 'warning'
        this.$store.state.snackBarActivo = true
        return
      }
      this.optionsFormaPago = peticion.financiaciones.filter(element => element != null && element != undefined && element != 'null' && element != '' && element.VIGENTE == 1)
      /*let tarjetas = peticion.financiaciones
      let tarjetasVigentes = []
      console.log('tarjetas', tarjetas)
      for (let id in tarjetas){
        if (tarjetas[id] != null && tarjetas[id] != 'null' && tarjetas[id] != "null" && tarjetas[id] != undefined && tarjetas[id] != ''){
          if(parseInt(tarjetas[id].VIGENTE) == 0){
            console.log('pasa a agregar', tarjetas[id])
            tarjetasVigentes.push(tarjetas[id])
          }
        }
      }
      this.optionsFormaPago = tarjetasVigentes*/
      // pido las tarjetas
      let peticionTar = await this.$store.dispatch('cobros/getTarjetas')
      if (peticionTar.resultado == 0){
        this.$store.state.modalCarga = false
        // solo muestro el error
        this.$store.state.msjSnackBar = peticionTar.msj
        this.$store.state.colorSnackBar = 'warning'
        this.$store.state.snackBarActivo = true
        return
      }
      this.optionsTarjetas = peticionTar.tarjetas
      this.$store.state.modalCarga = false
    },
    async getInfoEmpresa(){
      let empPeticion = await this.$store.dispatch('firebaseService/getEmpresaInfo', {empresa: this.empresa})
      if (empPeticion.resultado == 0){
        this.$store.state.modalCarga = false
        // solo muestro el error
        this.$store.state.msjSnackBar = empPeticion.msj
        this.$store.state.colorSnackBar = 'warning'
        this.$store.state.snackBarActivo = true
        return
      }
      this.permiteIngresarFinanciacion = empPeticion.empresa.ingresa_financicion
    },
    filterFinanciacion(){
      this.optionsFormaPagoFilter = this.optionsFormaPago.filter( fp => fp.TARJETA_CODIGO == this.tipo_tarjeta.TARJETA_CODIGO )
    },
    async generarlink(){
      if(isNaN(this.importe)){
        this.$swal({title: 'Importe no válido.',icon:'warning'})
        return
      }
      if(!this.$refs.formDatosLink.validate()){
        return
      }
      this.showLoading = false
      let param = {}
      if (this.permiteIngresarFinanciacion == 1){
        let ingresa_tipo_tarjeta = 0
        if (Object.keys(this.tipo_tarjeta).length > 0){
          ingresa_tipo_tarjeta = this.tipo_tarjeta
        }
        let ingresa_forma_pago = 0
        if (Object.keys(this.forma_pago).length > 0){
          ingresa_forma_pago = this.forma_pago
        }
        param = {
          importe: this.importe,
          descripcion: this.descripcion,
          telefono: this.telefono,
          correo : this.correo,
          ingresa_financiacion: 1,
          ingresa_tipo_tarjeta: ingresa_tipo_tarjeta,
          ingresa_forma_pago: ingresa_forma_pago,
          empresa: this.empresa,
          ipPublica: this.currentLocation
        }
      }else{
        param = {
          importe: this.importe,
          descripcion: this.descripcion,
          telefono: this.telefono,
          correo: this.correo,
          ingresa_financiacion: 0,
          ingresa_tipo_tarjeta: 0,
          ingresa_forma_pago: 0,
          empresa: this.empresa,
          ipPublica: this.currentLocation
        }
      }
      // creación del boton
      let createPeticion = await this.$store.dispatch('botonPago/crearBotonPago', param)
      if (createPeticion.resultado == 1){
        this.srvlinks = createPeticion.link
      }else{
        this.$swal.fire({
          title: 'Generando Link de Pago',
          text: 'No se pudo generar el Link deseado: ' + createPeticion.msj,
          icon: 'error'
        })
      }
    },
    copyLink(){
      let testingCodeToCopy = document.querySelector('#link-pago-generado')
      testingCodeToCopy.setAttribute('type', 'text')  
      testingCodeToCopy.select()
      let  successful = document.execCommand('copy');        
      alert('Link de Pago copiado.') 
    }
  },
  watch: {
    activo: function(){
      this.modalActivo = this.activo
    },
    modalActivo: function(){
      if (this.modalActivo == false){
        this.$emit('cerrarModalNLP')
      }
    },
    srvlinks(){
      if(this.srvlinks.length != 0){
        this.modalActivo = false
        this.dialogLinkSuccess = true
      }
    }
  },
}
</script>

<style>
  .slide-fade-enter-active {
    transition: all .6s ease;
    }
    .slide-fade-leave-active {
    transition: all .6s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
    }
    .titleSuccesMsj{
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-size: 20px;  
    }
</style>