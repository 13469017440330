<template>
  <v-dialog
    v-model="activo"
    max-width="40%"
  >
    <v-card flat>
      <v-card-title class="pa-0">
        <v-toolbar color="info">
          <v-toolbar-title class="white--text">
            <v-icon class="ma-2" color="white">fas fa-exclamation-circle</v-icon> Seleccione el Tipo de Anulación a realizar
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-row>
          <v-col cols="12" class="py-0">
            <v-checkbox
              v-model="anulaCompleta"
              label="Anulación Completa: anular por el total del cobro."
              color="info"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-checkbox
              v-model="anulaParcial"
              label="Anulación Parcial: anular una parte del cobro."
              color="info"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="py-0 mt-4" v-if="anulaParcial == true">
            <v-text-field
              v-model="importe"
              outlined
              dense
              type="number"
              label="Importe"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="success" @click="seleccionarTipoAnulacion()">Seleccionar</v-btn>
        <v-btn small color="warning" @click="activo = false">Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {convertDecimals} from '../../util/util'
export default {
  props: {
    datosModal: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get(){
        return this.datosModal.activo
      },
      set(value){
        this.$emit('setearModalTipAnula', value)
      }
    },
    importeCobro: {
      get(){
        return this.datosModal.importeAnular
      }
    }
  },
  data() {
    return {
      anulaCompleta: false,
      anulaParcial: false,
      importe: ''
    }
  },
  methods: {
    verificarInputs(){
      if (this.anulaCompleta == false && this.anulaParcial == false){
        return {msj: 'Debe seleccionar un Tipo de Anulación.', bandera: false}
      }
      if (this.anulaParcial == true && this.importe.toString().length == 0){
        return {msj: 'Debe ingresar el monto a anular.', bandera: false}
      }
      if (this.anulaParcial == true && this.importe.toString().length > 0 && convertDecimals(this.importe) > convertDecimals(this.importeCobro)){
        return {msj: 'No se puede anular por un monto mayor al cobrado.', bandera: false}
      }
      return {msj: 'OK', bandera: true}
    },
    seleccionarTipoAnulacion(){
      let control = this.verificarInputs()
      if (control.bandera == false){
        this.$store.state.msjSnackBar = control.msj
        this.$store.state.colorSnackBar = 'warning'
        this.$store.state.snackBarActivo = true
        return
      }
      let anuloCompleto = this.anulaCompleta
      this.$emit('setearTipoAnulacion', {importe: this.importe, completo: anuloCompleto})
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.anulaCompleta = false
        this.anulaParcial = false
        this.importe = ''
      }
    },
    anulaCompleta: function(){
      if (this.anulaCompleta == true){
        this.anulaParcial = false
      }
    },
    anulaParcial: function(){
      if (this.anulaParcial == true){
        this.anulaCompleta = false
      }
    }
  },
}
</script>

<style>

</style>