<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Encabezado -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn
              style="position: absolute; top: -15px; right: 130px; z-index: 1"
              color="success"
              small
              fab
              @click="openDialogNewLink()"
              title="Nuevo Link"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="getLinksPago">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Importe
                    <v-text-field
                      v-model="importe"
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="descripcion"
                      :items="estados"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Telefono
                    <v-text-field
                      v-model="telefono"
                      outlined
                      dense
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row :no-gutters="$vuetify.breakpoint.xs" style="margin-top: -20px">
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Fecha
                    <FechaPickerRango
                      v-model="fechaRango"
                      :limpiar.sync="limpiarFecha"
                    />
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="mt-3">
                    <v-btn color="info" title="Empezar Búsqueda" type="submit">Buscar</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Links -->
        <v-data-table
            class="cebra elevation-2 mt-2"
            :headers="headers"
            :items="links"
            :search="search"
            :loading="load"
          >
            <!-- Buscador en el datatable -->
            <template v-slot:top>
              <v-row class="d-flex justify-end pa-2" no-gutters>
                <v-col cols="6" sm="3">
                  <searchDataTable
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </template>
            <!-- Acciones y modal para confirmar -->
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip bottom v-if="item.tiene_error == 1">
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="warning" @click="visualizarError(item)" v-on="on">
                    <v-icon small>fas fa-exclamation-circle</v-icon>
                  </v-btn>
                </template>
                <span>Error</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.estado_inhabilita == 1">
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="error" @click="confirmarInhabilitar(item)" v-on="on">
                    <v-icon small>fas fa-ban</v-icon>
                  </v-btn>
                </template>
                <span>Inhabilitar</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.estado != 'ANULADO'">
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="success" @click="syncBoton(item)" v-on="on">
                    <v-icon small>fas fa-sync-alt</v-icon>
                  </v-btn>
                </template>
                <span>Sincronizar</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.estado == 'APROBADO'">
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="primary" @click="visualizar(item)" v-on="on">
                    <v-icon small>fas fa-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.estado == 'APROBADO' || item.estado == 'ANULADO'">
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="success" @click="descargarComprobante(item)" v-on="on">
                    <v-icon small>fas fa-file-download</v-icon>
                  </v-btn>
                </template>
                <span>Descargar Comprobante</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.estado == 'APROBADO'">
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="error" @click="confirmarTipoAnulacion(item)" v-on="on">
                    <v-icon small>fas fa-times-circle</v-icon>
                  </v-btn>
                </template>
                <span>Anular</span>
              </v-tooltip>
            </template>
            <!-- Estado -->
            <template v-slot:[`item.estado`]="{ item }">
              <v-chip small :color="getColor(item.estado)" dark>{{ item.estado }}</v-chip>
            </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Ver Link de Pago -->
    <modalVerLinkPago 
      :activo="modalLinkActivo"
      :datosCupon="datosCupon"
      @cerrarModalVLP="closeModaVLP"
    />
    <!--Modal Generar Link Pago -->
    <modalNuevoLinkPago 
      :activo="modalLinkNuevoActivo"
      :empresa="empresa"
      @cerrarModalNLP="closeModalNLP"
    />
    <!-- Modal Decidir Tipo de Anulación -->
    <tipoAnulacion 
      :datosModal="tipoAnulacionData"
      @setearModalTipAnula="setModalTipoAnula"
      @setearTipoAnulacion="setTipoAnulacion"
    />
    <!-- Decidir Inhabilitar Botón de Pago -->
    <modalDecidir 
      :datos="dialogInhabilitar"
      @setearModalDecision="setModalDecision"
      @ejecutar="inhabilitar"
    />
    <!-- Dcidir Anular Botón de Pago -->
    <modalDecidir 
      :datos="dialogAnular"
      @setearModalDecision="setModalAnular"
      @ejecutar="anular"
    />
  </div>
</template>

<script>
import moment from 'moment'
import router from '../router'
import {mapActions} from 'vuex'
import modalVerLinkPago from '../components/linkPago/modalVerLinkPago.vue'
import modalNuevoLinkPago from '../components/linkPago/modalNuevoLinkPago.vue'
import tipoAnulacion from '../components/util/tipoAnulacion.vue'
import {convertDecimals, roundNumber} from '../util/util'
import searchDataTable from '../components/generales/searchDataTable.vue'
import modalDecidir from '../components/generales/modalDecision.vue'
import FechaPickerRango from '../components/util/FechaPickerRango.vue'
export default {
  data() {
    return {
      panel: 0,
      importe: '',
      selectedEstado: {},
      estados: [],
      telefono: '',
      headers: [
        { text: 'Fecha', align: 'center', value: 'fecha_grabacion' },
        { text: 'Importe', align: 'center', value: 'importe' },
        { text: 'Descripcion', align: 'center', value: 'descripcion' },
        { text: 'Teléfono', align: 'center', value: 'telefono' },
        { text: 'Link', align: 'center', value: 'link' },
        { text: 'Estado', align: 'center', value: 'estado' },
        { text: 'Acciones', align: 'center', value: 'acciones' },
      ],
      links: [],
      search: '',
      empresa: JSON.parse(localStorage.getItem('empresa')),
      modalLinkActivo: false,
      datosCupon: {},
      modalLinkNuevoActivo: false,
      keyDialogLink: '',
      tipoAnulacionData: {
        activo: false,
        importeAnular: 0
      },
      selectedLink: {},
      load: false,
      importeAnular: '0',
      dialogInhabilitar: {
        activo: false,
        titulo: '',
        texto: '',
        icono: '',
        color: ''
      },
      dialogAnular: { 
        activo: false,
        titulo: '',
        texto: '',
        icono: '',
        color: ''
      },
      limpiarFecha: false,
      fechaRango: [null, null]
    }
  },
  components: {
    modalVerLinkPago,
    modalNuevoLinkPago,
    tipoAnulacion,
    searchDataTable,
    modalDecidir,
    FechaPickerRango
  },
  created () {
    this.iniciarFormulario()
  },
  methods: {
    ...mapActions(['logOutFirebase']),
    async logout(){
      this.$store.state.modalCarga = true
      await this.logOutFirebase({})
    },
    async getLinksPago(){
      this.$store.state.modalCarga = true
      // obtengo el usuario que se encuentra logueado
      let usuario = await this.$store.dispatch('firebaseService/getCurrentUser')
      if (usuario.resultado == 0 || usuario.resultado == 2){
        this.$store.state.modalCarga = false
        // desloguear
        await this.logOutFirebase({
          msj: usuario.msj,
          color: 'warning',
          activo: true,
          sesionExpirada: 1
        })
        return
      }
      // asigno el uid
      let uid = usuario.usuario.uid
      // verifico si tiene permiso para usar la empresa
      let permisoEmpresa = await this.$store.dispatch('firebaseService/verifyUserXEmpresa', {empresa: this.empresa, uid: uid})
      if (permisoEmpresa.resultado == 2 || permisoEmpresa.resultado == 0){
        this.$store.state.modalCarga = false
        // no autorizado para la empresa
        await this.logOutFirebase({
          msj: permisoEmpresa.msj,
          color: 'error',
          activo: true,
          sesionExpirada: 1
        })
        return
      }
      // armo los filtros
      let impParametro = 0
      if (this.importe.length > 0){
        impParametro = this.importe
      }
      let estadoParam = ''
      if (Object.keys(this.selectedEstado).length > 0){
        estadoParam = this.selectedEstado.descripcion
      }
      let telefonoParam = ''
      if (this.telefono.length > 0){
        telefonoParam = this.telefono
      }
      let fechaDesde = moment(this.fechaRango[0]).format('YYYY-MM-DD')
      let fechaHasta = ''
      if (this.fechaRango[1] != null && this.fechaRango[1] != undefined && this.fechaRango[1].toString().length > 0){
        fechaHasta = moment(this.fechaRango[1]).format('YYYY-MM-DD')
      }
      let filtros = {
        importe: impParametro,
        estado: estadoParam,
        telefono: telefonoParam,
        fecha_desde: fechaDesde,
        fecha_hasta: fechaHasta
      }
      let linksPeticion = await this.$store.dispatch('botonPago/getLinks', {filtros: filtros, empresa: this.empresa})
      if (linksPeticion.resultado == 0){
        this.$store.state.modalCarga = false
        this.$store.state.msjSnackBar = linksPeticion.msj
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        return
      }
      if (linksPeticion.links.length > 0){
        this.links = this.rearmarLinks(linksPeticion.links, uid)
      }else{
        this.links = []
      }
      this.$store.state.modalCarga = false
    },
    rearmarLinks(links, uid){
      try{
        let listado = []
        for (let id in links){
          // solo me quedo con los links generados por el usuario
          if (links[id].usuario_uid == uid){
            let unLink = {}
            // obtengo el estado
            let unEstado = this.estados.filter(element => element.descripcion.toUpperCase() == links[id].estado.toUpperCase())
            let estadoInhabilita = unEstado[0].estado_inhabilita
            let tieneError = unEstado[0].tiene_error
            if (links[id].estado == 'APROBADO'){
              let tarjetaMarca = ''
              if (links[id].hasOwnProperty('tarjeta_marca')){
                tarjetaMarca = links[id].tarjeta_marca
              }
              let tarjetaNumero = ''
              if (links[id].hasOwnProperty('tarjeta_numero')){
                tarjetaNumero = links[id].tarjeta_numero
              }
              let financiacion = ''
              if (links[id].hasOwnProperty('financiacion')){
                financiacion = links[id].financiacion
              }
              let importeCobrado = ''
              if (links[id].hasOwnProperty('importe_cobrado')){
                importeCobrado = links[id].importe_cobrado
              }
              let correo = ''
              if (links[id].hasOwnProperty('correo')){
                correo = links[id].correo
              }
              let transaccionId = '0'
              if (links[id].hasOwnProperty('Ipg_TransaccionID')){
                transaccionId = links[id].Ipg_TransaccionID
              }
              unLink = {
                id: links[id].keyFirebase,
                usuario_uid: uid,
                estado: links[id].estado,
                descripcion: links[id].descripcion,
                telefono: links[id].telefono,
                importe: links[id].importe,
                fecha_log: links[id].fecha_log,
                fecha_grabacion: links[id].fecha_log,
                lote: links[id].lote,
                numero: links[id].numero,
                codigo_autorizacion: links[id].codigo_autorizacion,
                fecha_cupon: links[id].fecha_cupon,
                link: links[id].link,
                tarjeta_marca: tarjetaMarca,
                tarjeta_numero: tarjetaNumero,
                financiacion: financiacion,
                titular: links[id].titular,
                importe_cobrado: importeCobrado,
                estado_inhabilita: estadoInhabilita,
                tiene_error: tieneError,
                correo: correo,
                transaccion_id: transaccionId,
                fecha_log_o: links[id].fecha_log,
                ingresa_financiacion: links[id].ingresa_financiacion,
                ingresa_forma_pago: links[id].ingresa_forma_pago,
                ingresa_tipo_tarjeta: links[id].ingresa_tipo_tarjeta
              }
            }else{
              let telefono = '-'
              if (links[id].hasOwnProperty('telefono')){
                telefono = links[id].telefono
              }
              let errorMessage = 'Error no definido'
              if (links[id].hasOwnProperty('error_mensaje')){
                errorMessage = links[id].error_mensaje
              }
              let error_descripcion = 'Error no definido'
              if (links[id].hasOwnProperty('error_descripcion')){
                error_descripcion = links[id].error_descripcion
              }
              let transaccionId = '0'
              if (links[id].hasOwnProperty('Ipg_TransaccionID')){
                transaccionId = links[id].Ipg_TransaccionID
              }
              let correo = ''
              if (links[id].hasOwnProperty('correo')){
                correo = links[id].correo
              }
              unLink = {
                id: links[id].keyFirebase,
                usuario_uid: uid,
                estado: links[id].estado,
                descripcion: links[id].descripcion,
                telefono: telefono,
                importe: links[id].importe,
                fecha_log: links[id].fecha_log,
                fecha_grabacion: links[id].fecha_log,
                link: links[id].link,
                estado_inhabilita: estadoInhabilita,
                error_mensaje: errorMessage,
                error_descripcion: error_descripcion,
                tiene_error: tieneError,
                transaccion_id: transaccionId,
                fecha_log_o: links[id].fecha_log,
                ingresa_financiacion: links[id].ingresa_financiacion,
                ingresa_forma_pago: links[id].ingresa_forma_pago,
                ingresa_tipo_tarjeta: links[id].ingresa_tipo_tarjeta,
                correo: correo
              }
            }
            unLink.fecha_grabacion = moment(unLink.fecha_grabacion).format('DD/MM/YYYY')
            unLink.fecha_log = moment(unLink.fecha_log).format('DD/MM/YYYY')
            listado.push(unLink)
          }
        }
        return listado
      }catch(error){
        return []
      }
    },
    async iniciarFormulario(){
      this.$store.state.modalCarga = true
      // obtengo el usuario que se encuentra logueado
        let usuario = await this.$store.dispatch('firebaseService/getCurrentUser')
        if (usuario.resultado == 0 || usuario.resultado == 2){
          this.$store.state.modalCarga = false
          // desloguear
          await this.logOutFirebase({
            msj: usuario.msj,
            color: 'warning',
            activo: true,
            sesionExpirada: 1
          })
          return
        }
        // asigno el uid
        let uid = usuario.usuario.uid
        // obtengo los estados de cobros
        let estados = await this.$store.dispatch('cobros/getEstadosCobro')
        if (estados.resultado == 0){
          this.$store.state.modalCarga = false
          // solo muestro el error
          this.$store.state.msjSnackBar = estados.msj
          this.$store.state.colorSnackBar = 'warning'
          this.$store.state.snackBarActivo = true
          return
        }
        this.estados = estados.estados
        // verifico si tiene permiso para usar la empresa
        let permisoEmpresa = await this.$store.dispatch('firebaseService/verifyUserXEmpresa', {empresa: this.empresa, uid: uid})
        if (permisoEmpresa.resultado == 2 || permisoEmpresa.resultado == 0){
          this.$store.state.modalCarga = false
          // no autorizado para la empresa
          await this.logOutFirebase({
            msj: permisoEmpresa.msj,
            color: 'error',
            activo: true,
            sesionExpirada: 1
          })
          return
        }
        // tiene permiso, obtengo los links
        let fechaDesde = moment(this.fechaRango[0]).format('YYYY-MM-DD')
        let links = await this.$store.dispatch('cobros/getLinksPagoEmpresa', {empresa: this.empresa, fecha_desde: fechaDesde, fecha_hasta: ''})
        if (links.resultado == 0){
          this.$store.state.modalCarga = false
          // no se pudo obtener los links de pago
          this.$store.state.msjSnackBar = links.msj
          this.$store.state.colorSnackBar = 'warning'
          this.$store.state.snackBarActivo = true
          return
        }
        // rearmo las estructuras
        if (Object.values(links).length > 0){
          this.links = this.rearmarLinks(links.links, uid)
        }else{
          this.links = []
        }
        this.$store.state.modalCarga = false
    },
    getColor (estado) {
      if (estado == 'APROBADO') return 'green'
      else if (estado == 'ANULADO' || estado == 'FAILED' || estado ==  'INHABILITADO' || estado == 'DEVOLUCION') return 'red'
      else if (estado == 'RECHAZADO' || estado == 'PROCESADO CON ERROR' ) return 'orange'
      else return 'blue'
    },
    visualizarError(item){
      let texto = ''
      if (item.error_descripcion != 'Error no definido'){
        texto = item.error_descripcion
      }else{
        texto = item.error_mensaje
      }
      this.$store.dispatch('executeModalAviso', {titulo: 'Visualizar Error', texto: texto, icono: 'fas fa-exclamation-circle', color: 'warning'})
    },
    confirmarInhabilitar(item){
      this.selectedLink = item
      this.dialogInhabilitar.titulo = 'Inhabilitar Botón de Pago'
      this.dialogInhabilitar.texto = `Está inhabilitando el botón ${item.id}. ¿confirma esta acción?`
      this.dialogInhabilitar.icono = 'fas fa-exclamation-circle'
      this.dialogInhabilitar.color = 'warning'
      this.dialogInhabilitar.activo = true
    },
    async inhabilitar(){
      this.$store.state.modalCarga = true
      let param = {
        empresa: this.empresa,
        key: this.selectedLink.id
      }
      let updatePeticion = await this.$store.dispatch('botonPago/inhabilitarBotonPago', param)
      this.$store.state.modalCarga = false
      let texto = ''
      let color = ''
      if (updatePeticion.resultado == 1){
        texto = 'El Link de Pago fue Inhabilitado con éxito.'
        color = 'success'
        let pos = this.links.indexOf(this.selectedLink)
        this.links[pos].estado = 'INHABILITADO'
      }else{
        texto = 'No se pudo inhabilitar el Link deseado: ' + updatePeticion.msj
        color = 'error'
      }
      this.$store.dispatch('executeModalAviso', {titulo: 'Inhabilitando Link de Pago', texto: texto, icono: 'fas fa-exclamation-circle', color: color})
    },
    visualizar(item){
      this.datosCupon = JSON.parse( JSON.stringify( item ) )
      this.modalLinkActivo = true
    },
    async descargarComprobante(item){
      this.$store.state.modalCarga = true
      let archivoPeticion = await this.$store.dispatch('cobros/getComprobante', item)
      this.$store.state.modalCarga = false
      if (archivoPeticion.resultado == 1){
        download('data:application/pdf;base64,' + archivoPeticion.pdf64, "Cobro.pdf", "application/pdf")
      }else{
        this.$store.state.msjSnackBar = 'No se pudo descargar el comprobante solicitado: ' + archivoPeticion.message
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        return
      }
    },
    confirmarAunlacion(){
      this.dialogAnular.titulo = 'Anular Cobro'
      if (convertDecimals(this.importeAnular) > 0){
        this.dialogAnular.texto = `Está anulando el botón ${this.selectedLink.id} por un total de $${this.importeAnular}. ¿Confirma esta acción?`
      }else{
        this.dialogAnular.texto = `Está anulando el botón ${this.selectedLink.id}. ¿Confirma esta acción?`
      }
      this.dialogAnular.icono = 'fas fa-exclamation-circle'
      this.dialogAnular.color = 'warning'
      this.dialogAnular.activo = true
    },
    async anular(){
      let parcial = 0
      if (convertDecimals(this.importeAnular) > 0){
        parcial = 1
      }
      this.$store.state.modalCarga = true
      let datosAnulacion = {
        id: this.selectedLink.transaccion_id,
        empresa_id: this.empresa,
        keyBotonPago: this.selectedLink.id,
        importe: this.importeAnular
      }
      let anulacionPeticion = await this.$store.dispatch('cobros/cancelPayment', datosAnulacion)
      if (anulacionPeticion.resultado == 1){
        // cobro anulado con exito, actualizo en el array de botones
        
        //paso a actualizar el nodo de firebase
        this.completarBotonPago(this.selectedLink, parcial)
      }else{
        this.$store.state.modalCarga = false
        this.$store.dispatch('executeModalAviso', {titulo: 'Anular Cobro', texto: 'No se pudo anular el cobro: ' + anulacionPeticion.msj, icono: 'fas fa-exclamation-circle', color: 'error'})
      }
    },
    async completarBotonPago(datosBoton, parcial){
      // paso a actualizar
      let updatePeticion = await this.$store.dispatch('botonPago/completarAnulacion', {key: datosBoton.id, empresa: this.empresa, parcial: parcial, importe: convertDecimals(this.importeAnular)})
      if (updatePeticion.resultado == 0){
        this.$store.dispatch('executeModalAviso', {titulo: 'Anular Cobro', texto: 'El cobro se anuló pero no se pudo actualizar los datos del botón de pago: ' + updatePeticion.msj, icono: 'fas fa-exclamation-circle', color: 'warning'})
        return
      }
      if (updatePeticion.anular == 1){
        // actualización correcta, enviar el mail de confirmación de anulación
        this.sendMailAnulacion(datosBoton)
        let pos = this.links.indexOf(datosBoton)
        this.links[pos].estado = 'ANULADO'
      }else{
        this.$store.state.modalCarga = false
        this.$store.dispatch('executeModalAviso', {titulo: 'Ejecutar Anulación Cobro', texto: 'El cobro se anuló correctamente', icono: 'fas fa-exclamation-circle', color: 'success'})
        setTimeout(() => [window.location.reload()], 3000)
      }
    },
    async sendMailAnulacion(datosBoton){
      // grabar una peticion de envio de mail de anulacion
      let templateId = 0
      let carpetaTemplate = ''
      if (this.empresa == 1){
        templateId = 7
        carpetaTemplate = 'DycarCitroen'
      }else if (this.empresa == 2){
        templateId = 5
        carpetaTemplate = 'CeluShop'
      }else if (this.empresa == 3){
        templateId = 6
        carpetaTemplate = 'DycarChevrolet'
      }else if (this.empresa == 5){
        templateId = 8
        carpetaTemplate = 'Rada'
      }else if(this.empresa == 15){
        templateId = 13
        carpetaTemplate = 'EuroDycar'
      }else{
        // sera dycar citroen? empresa 12
        templateId = 7
        carpetaTemplate = 'DycarCitroen'
      }
      let body = {
        remitente: 'no-responder@borigenbetzel.com.ar',
        asunto: 'Anulación Exitosa!',
        destinatario: datosBoton.correo,
        proceso: 'Anula Botón de Pago',
        pedido_numero: datosBoton.transaccion_id,
        id_template: templateId, // esto es el id del template que depende de la empresa
        carpeta_template: carpetaTemplate, // esto es la carpeta contenedora del template que vamos a usar
        casilla_id: 1,
        parametros: [
          {
            nombreParam: 'nombre',
            valorParam: datosBoton.titular
          },
          {
            nombreParam: 'importe',
            valorParam: datosBoton.importe_cobrado
          }
        ],
        archivos_adjuntos: [
          {
            plantilla_id: 2 // esto es el comprobante de pago
          }
        ]
      }
      // envio una peticion de mail
      let mailPeticion = await this.$store.dispatch('cobros/sendEmailPetition', body)
      this.$store.state.modalCarga = false
      let texto = ''
      let color = ''
      if (mailPeticion.resultado == 1){
        texto = 'El cobro se anuló correctamente'
        color = 'success'
      }else{
        texto = 'El cobro se anuló correctamente pero no se pudo enviar el mail al Cliente: ' + mailPeticion.msj
        color = 'warning'
      }
      this.$store.dispatch('executeModalAviso', {titulo: 'Ejecutar Anulación Cobro', texto: texto, icono: 'fas fa-exclamation-circle', color: color})
      setTimeout(() => [window.location.reload()], 3000)
    },
    closeModaVLP(){
      this.modalLinkActivo = false
    },
    openDialogNewLink(){
      this.keyDialogLink = Math.floor(Math.random() * (99999 - 1)) + 1
      this.modalLinkNuevoActivo = true
    },
    closeModalNLP(){
      this.modalLinkNuevoActivo = false
    },
    confirmarTipoAnulacion(item){
      // guardo el link seleccionado
      this.selectedLink = item
      // abro el modal
      this.tipoAnulacionData.importeAnular = item.importe
      this.tipoAnulacionData.activo = true
    },
    setModalTipoAnula(value){
      this.tipoAnulacionData.activo = value
    },
    setTipoAnulacion(value){
      this.importeAnular = '0'
      if (value.completo == false){
        // si no anula completo, entonces es parcial
        this.importeAnular = convertDecimals(value.importe)
      }
      // preguntar si quiere anular
      this.confirmarAunlacion()
    },
    setModalDecision(value){
      this.dialogInhabilitar.activo = value
    },
    setModalAnular(value){
      this.dialogAnular.activo = value
    },
    async syncBoton(item){
      // 29-08-2024 por MMURILLO, controlo si el botón de pago ya fué aprobado
      this.$store.state.modalCarga = true
      let statusPeticion = await this.$store.dispatch('cobros/getPaymentStatus', {
        key_firebase: item.id,
        empresa_codigo: this.empresa
      })
      if (statusPeticion.resultado == 0){
        this.$store.state.modalCarga = false
        this.$store.state.msjSnackBar = statusPeticion.message
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        return
      }
      if ((statusPeticion.estado == 2 || statusPeticion.estado == 4) && item.estado.toString().toUpperCase() != 'APROBADO' && item.estado.toString().toUpperCase() != 'ANULADO'){
        let updatePeticion = await this.$store.dispatch('botonPago/updateBoton', {
          key_firebase: item.id,
          empresa_codigo: this.empresa,
          estado: statusPeticion.estado,
          marca: statusPeticion.marca,
          importe: roundNumber(statusPeticion.importe, 2),
          tdate: statusPeticion.tdate,
          transaccion_id: statusPeticion.transaccion_id,
          numero: statusPeticion.numero,
          lote: statusPeticion.lote,
          codigo_autorizacion: statusPeticion.codigo_autorizacion,
          titular: statusPeticion.titular,
          documento: statusPeticion.documento,
          nro_tarjeta: statusPeticion.nro_tarjeta,
          usuario_uid: item.usuario_uid,
          descripcion: item.descripcion,
          telefono: item.telefono,
          link: item.link,
          fecha_log: item.fecha_log_o,
          ingresa_financiacion: item.ingresa_financiacion,
          ingresa_forma_pago: item.ingresa_forma_pago,
          ingresa_tipo_tarjeta: item.ingresa_tipo_tarjeta,
          correo: item.correo
        })
        if (updatePeticion.resultado == 0){
          this.$store.state.modalCarga = false
          this.$store.state.msjSnackBar = 'Ocurrió un problema al actualizar los datos del Botón de Pago. Intente nuevamente más tarde.'
          this.$store.state.colorSnackBar = 'error'
          this.$store.state.snackBarActivo = true
          return
        }
        this.$store.dispatch('executeModalAviso', {titulo: 'Sincronizar Cobro', texto: 'Se actualizaron los datos de su Cobro.', icono: 'fas fa-exclamation-circle', color: 'success'})
        this.getLinksPago()
      }
      this.$store.state.modalCarga = false
    }
  },
  watch: {

  },
}
</script>
